.bg-vector {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
}


h2 {
    font-weight: 400;
}

iframe {
    display: none;
}