@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700&display=swap);
.bg-vector {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
}


h2 {
    font-weight: 400;
}

iframe {
    display: none;
}
h2 {
    color: white;
}

.loading svg {
    height: 30px;
}
* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.nft-video {
  z-index: 100;
  display: block;
  position: relative;
  border-radius: 10px;
  box-shadow: 4px 4px 1px 0px rgba(0,223,0,0.57);
}

.nft-background-image {
  position: absolute;
  top: -123px;
  left: -166px;
  min-width: 580px;
  z-index: 5;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.video-wrapper {
  position: relative;
}

.info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #1817dd;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  font-size: 30px;
}

.main {
  min-height: 440px;
  min-width: 960px;
  max-width: 1000px;
  margin: 0 30px;
  width: 100%;
  background-color: rgba(0, 0, 3, 0.3);
  -webkit-backdrop-filter: blur(54px);
          backdrop-filter: blur(54px);
  grid-template-columns: 1fr 1fr;
  display: grid;
  border-radius: 15px;
  color: white;
  position: relative;
  z-index: 1000;
}

.main-right-minting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding-left: 50px;
}

.main-left-nft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
}

.actions {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.actions button {
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 50px;
  font-size: 14px;
  border: none;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.filled-button {
  color: black;
  background-color: #00DF00;
}

.transparent-button {
  color: #00DF00;
  background-color: transparent;
  border: 1px solid #00DF00 !important;
}

.actions button:hover {
  background-color: #261A44;
  color: #00DF00;
}

.description {
  color: #ffffff;
}

@media only screen and (max-width: 950px) {
  /* For mobile phones: */
  .main {
    overflow: hidden;
    display: grid;
    grid-template-rows: none;
    grid-template-columns: none;
    flex-direction: column;
    align-items: normal;
    grid-gap: 0;
    gap: 0;
    width: 100vw;
    border-radius: 0;
    height: 100%;
    margin: 0;
    justify-content: center;
    padding: 0;
    min-width: 0;
  }

  .main-right-minting {
    max-width: 450px;
    margin: 0 20px;
    order: 1;
    padding-left: 0;
    justify-content: flex-start;
  }

  .main-left-nft {
    order: 0;
  }

  .actions button {
    padding: 15px 20px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  max-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #1B112C;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}


